<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ 'show': shallShowActiveChatContactSidebar }"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!chatConversation.length"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Yozishmani boshlash
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.profile_picture_url"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                badge-variant="success"
                @click.native="shallShowActiveChatContactSidebar = true"
              />

              <h6 class="mb-0">
                {{ activeChat.full_name }}
              </h6>

              <div class="ml-3">
                Telefoni:
                <b-badge
                  pill
                  variant="success"
                >
                  {{ activeChat.phone }}
                </b-badge>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :chat-conversation="chatConversation"
          />
        </vue-perfect-scrollbar>

        <!-- Scroll Bottom Button -->
        <!-- <button
          class="scroll-bottom-btn"
          @click="scrollToBottomInChatLog"
        >
          <feather-icon
            icon="ArrowDownIcon"
            size="16"
          />
        </button> -->

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Xabar yozing"
            />
          </b-input-group>
          <b-overlay :show="loadingBtn">
            <b-button
              variant="primary"
              type="submit"
            >
              Yuborish
            </b-button>
          </b-overlay>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :conversations-list="conversationsList"
        @open-chat="openChatOfConversation"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import ChatLeftSidebar from './telegram/sidebar.vue'
import chatStoreModule from './telegram/chatStoreModule'
import ChatLog from './telegram/ChatLog.vue'

export default {
  components: {
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatLog,
  },
  data() {
    return {
      conversationsList: [],
      chatConversation: [],
      activeChat: {},
      chatInputMessage: '',
      chatsContacts: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      loadingBtn: false,
      responseChatsLoading: false,
      profileUserData: {},
      profileUserDataMinimal: {},
      shallShowActiveChatContactSidebar: false,
      intervalId: null,
      intervalId2: null,
      intervalId3: null,
    }
  },
  created() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)
  },
  mounted() {
    this.getTgMessagesList()
    this.intervalId = setInterval(this.getTgMessagesList, 5000)
    this.intervalId2 = setInterval(this.getConversations, 3000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
    clearInterval(this.intervalId2)
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
  },
  methods: {
    ...mapActions({
      getTgMessages: 'shop/getChats',
      readMessage: 'shop/readMessage',
      sendTgMessage: 'shop/sendTgMessage',
    }),
    getTgMessagesList() {
      this.responseChatsLoading = true
      this.getTgMessages({ per_page: 500 })
        .then(res => {
          this.conversationsList = res.data
        })
        .finally(() => { this.responseChatsLoading = false })
    },
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    openChatOfConversation(userData) {
      this.chatInputMessage = ''
      this.changeQuery('tg_chat_id', userData.chat_id)
      store.dispatch('shop/getTgMessage', { id: userData.chat_id, relations: 'user' })
        .then(res => {
          this.chatConversation = res.data
          this.activeChat = userData
          this.$nextTick(() => { this.scrollToBottomInChatLog() })
        })
    },
    getConversations() {
      if (this.$route.query.tg_chat_id) {
        store.dispatch('shop/getTgMessage', { id: this.$route.query.tg_chat_id })
          .then(res => {
            this.chatConversation = res.data
            this.$nextTick(() => { this.scrollToBottomInChatLog() })
          })
      }
    },
    sendMessage() {
      if (!this.chatInputMessage) return
      this.loadingBtn = true
      this.sendTgMessage({ chat_id: this.$route.query.tg_chat_id, message: this.chatInputMessage }).then(response => {
        this.getConversations()
        this.readMessage(this.$route.query.tg_chat_id)
        this.chatInputMessage = ''
        this.$nextTick(() => { this.scrollToBottomInChatLog() })
      }).finally(() => {
        this.loadingBtn = false
      })
    },

    startConversation() {
      this.getTgMessagesList()
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-bottom-btn {
  position: fixed;
  right: 20px;
  bottom: 100px;
  padding: 10px 15px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.scroll-bottom-btn:hover {
  background: #0056b3;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
