<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-content"
      >
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>
        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search..."
                @input=""
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Chats Title -->
          <h4 class="chat-list-title">
            Chatlar
          </h4>
          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <b-overlay :show="!conversationsList.length">
              <chat-contact
                v-for="conversation in conversationsList"
                :key="conversation.user_id"
                :userr="conversation"
                tag="li"
                :class="{ 'active': activeChatContactId === conversation.user_id }"
                is-chat-contact
                @click="$emit('open-chat', conversation)"
              />
            </b-overlay>

          </ul>
        </vue-perfect-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatContact from './ChatContact.vue'

export default {
  components: {
    VuePerfectScrollbar,
    ChatContact,
  },
  props: {
    conversationsList: {
      type: Array,
      default: () => [],
    },
 
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchQuery: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  computed: {
  },
}
</script>
