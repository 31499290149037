<template>
  <div class="chats">
    <div
      v-for="(message, index) in chatConversation"
      :key="message.sender_id + String(index)"
      class="chat"
      :class="{ 'chat-left': message.type==2}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="light-primary"
        />
      </div>
      <div class="chat-body">
        <div
          v-if="message.attachment_type == 'image'"
          class="d-flex chat-content"
          :class="{ 'justify-content-end': message.sender_id != chatData.user_id }"
        >
          <el-image
            fluid
            style="width: 100px;"
            :src="message.attachment_url"
            :preview-src-list="[message.attachment_url]"
          />
        </div>
        <!-- <div
          v-else-if="message.attachment_type == 'audio'"
          class="d-flex chat-content"
          :class="{ 'justify-content-end': message.sender_id != chatData.user_id }"
        >
          <audio controls>
            <source
              :src="message.attachment_url"
              type="audio/ogg"
            >
            <source
              :src="message.attachment_url"
              type="audio/mpeg"
            >
            Your browser does not support the audio element.
          </audio>
        </div> -->
        <div
          v-else
          class="chatWrapper__row"
        >
          <template v-if="message.story_url">
            <div v-if="true">
              <video
                width="400"
                controls
              >
                <source
                  :src="message.story_url"
                  type="video/mp4"
                >
                Your browser does not support HTML video.
              </video>
            </div>
            <div v-else>
              <el-image
                fluid
                style="width: 100px;"
                :src="message.story_url"
                :preview-src-list="[message.story_url]"
              />
            </div>
          </template>

          <div
            class="chatWrapper__card"
            :class="{
              chatWrapper__income: message.type == 2,
              chatWrapper__send: message.type == 1
            }"
          >
            {{ message.message }}
            <span class="chatWrapper__card__time">{{ formatTime(message.created_at) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    chatConversation: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      contentType: {},
    }
  },
  computed: {
  },
  methods: {
    formatTime(timestamp) {
      const date = new Date(timestamp)
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
      return date.toLocaleString('en-GB', options)
    },
  },
}
</script>

<style></style>
