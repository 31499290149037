<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="userr.profile_picture_url"
      :badge="isChatContact"
      class="badge-minimal"
      badge-variant="danger"
      variant="light-secondary"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ userr.full_name }}
      </h5>
      <p class="card-text text-truncate">
        {{ userr.message }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{ formatDateYmd(userr.created_at) }}</small>
      <b-badge
        v-if="userr.read_count"
        pill
        variant="primary"
        class="float-right"
      >
        {{ userr.read_count }}
      </b-badge>
    </div>
  </component>
</template>

<script>

export default {
  components: {
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    userr: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
